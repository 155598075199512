/* ubuntu-300 - latin */
@font-face {
  font-family: Ubuntu-Light;
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/ubuntu-v15-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/ubuntu-v15-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/ubuntu-v15-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/ubuntu-v15-latin-300.woff')
      format('woff'),
    /* Modern Browsers */ url('./fonts/ubuntu-v15-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/ubuntu-v15-latin-300.svg#Ubuntu')
      format('svg'); /* Legacy iOS */
}
/* ubuntu-300italic - latin */
@font-face {
  font-family: Ubuntu-LightItalic;
  font-style: italic;
  font-weight: 300;
  src: url('./fonts/ubuntu-v15-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/ubuntu-v15-latin-300italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/ubuntu-v15-latin-300italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('./fonts/ubuntu-v15-latin-300italic.woff')
      format('woff'),
    /* Modern Browsers */ url('./fonts/ubuntu-v15-latin-300italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/ubuntu-v15-latin-300italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-regular - latin */
@font-face {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/ubuntu-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/ubuntu-v15-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/ubuntu-v15-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/ubuntu-v15-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('./fonts/ubuntu-v15-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/ubuntu-v15-latin-regular.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-500 - latin */
@font-face {
  font-family: Ubuntu-Medium;
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/ubuntu-v15-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/ubuntu-v15-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/ubuntu-v15-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/ubuntu-v15-latin-500.woff')
      format('woff'),
    /* Modern Browsers */ url('./fonts/ubuntu-v15-latin-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/ubuntu-v15-latin-500.svg#Ubuntu')
      format('svg'); /* Legacy iOS */
}
/* ubuntu-500italic - latin */
@font-face {
  font-family: Ubuntu-MediumItalic;
  font-style: italic;
  font-weight: 500;
  src: url('./fonts/ubuntu-v15-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/ubuntu-v15-latin-500italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/ubuntu-v15-latin-500italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('./fonts/ubuntu-v15-latin-500italic.woff')
      format('woff'),
    /* Modern Browsers */ url('./fonts/ubuntu-v15-latin-500italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/ubuntu-v15-latin-500italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-700 - latin */
@font-face {
  font-family: Ubuntu-Bold;
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/ubuntu-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/ubuntu-v15-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/ubuntu-v15-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/ubuntu-v15-latin-700.woff')
      format('woff'),
    /* Modern Browsers */ url('./fonts/ubuntu-v15-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/ubuntu-v15-latin-700.svg#Ubuntu')
      format('svg'); /* Legacy iOS */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.shortSurveyBtnContiner {
  width: '100%';
}

.takeSurveyBtn {
  background-color: #fec70f !important;
  color: #ffffff !important;
  width: 30%;
  font-size: 15px !important;
  border-radius: 0px !important;
  text-transform: none !important;
  font-family: Ubuntu !important;
  align-self: center;
}

.takeSurveyBtnSection {
  width: '100%';
  text-align: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html,
body,
#root,
.App {
  min-width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  line-height: 2;
}

.pointer {
  cursor: pointer;
}

.homeWrapper {
  margin: 0 auto;
  width: 100%;
  background-image: url('Home-Maze.jpg');
  background-repeat: no-repeat;
  background-size: 48%;
  background-position: 50px 40px;
  margin-top: 4.8vh;
  height: 89vh;
  position: absolute;
  opacity: 0.5;
  z-index: -1;
}

@media (max-width: 766px) {
  .homeWrapper {
    margin-top: 10px;
    background-size: 100%;
    background-position: 0px 0px;
  }
}

.aboutWrapper {
  margin: 0 auto;
  width: 100%;
  padding-top: 95px;
  margin-top: 35px;
  margin-bottom: 45px;
}

@media (max-width: 766px) {
  .aboutWrapper {
    padding-top: 0px;
  }
}

.homePage {
  display: flex;
  flex-direction: column;
  max-width: 806px;
  align-items: flex-start;
  height: 95vh;
  justify-content: center;
  margin: 0 auto;
  padding: 0 20px;
}

@media (max-width: 766px) {
  .homePage {
    padding: 20px;
    height: 100%;
    margin: 20px auto;
  }
}

.aboutPage {
  display: flex;
  flex-direction: column;
  max-width: 766px;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  font-family: Ubuntu;
}

@media (max-width: 766px) {
  .aboutPage {
    padding: 20px;
  }
}

.jumpToStartCont {
  max-width: 766px;
  justify-content: right;
  text-align: right;
  margin: 0 auto;
  margin-top: 5rem;
  padding-bottom: 0.1rem;
}

@media (max-width: 766px) {
  .jumpToStartCont {
    margin-top: 3rem;
  }
}
.aboutPageDivider {
  width: 100%;
  background-color: #fec70f;
  height: 7px;
}

.homePage h1 {
  color: #fec70f;
  font-size: 70px;
  text-decoration: solid;
  font-weight: bold;
  line-height: normal;
  margin: 0px;
  font-family: Ubuntu-Bold;
}

.aboutPage h1 {
  color: #fec70f;
  text-decoration: solid;
  font-weight: bold;
  margin: 0px;
  font-family: Ubuntu-Bold;
}

.homePage h2 {
  color: #fec70f;
  text-decoration: solid;
  font-weight: normal;
  line-height: normal;
  font-size: 36px;
  margin: 0px;
  font-family: Ubuntu;
}

@media (max-width: 766px) {
  .homePage h2 {
    margin: 0px auto;
  }
}

.aboutPage h2 {
  color: #fec70f;
  font-size: 52px;
  text-decoration: solid;
  font-weight: bold;
  margin: 0px;
  line-height: normal;
  font-family: Ubuntu;
}

.homePageTextType3 {
  font-size: 17px;
  color: #636466;
  text-align: start;
  margin: 0px;
  font-family: Ubuntu-MediumItalic;
}

.homePageTextType2 {
  color: #636466;
  font-size: 17px;
  line-height: 1.5;
  text-align: justify;
  margin: 0px;
  font-family: Ubuntu-Medium;
}

.homePageTextType1 {
  font-size: 17px;
  color: #636466;
  line-height: 1.5;
  text-align: justify;
  margin: 0px;
  font-family: Ubuntu-LightItalic;
}

.surveyButton {
  background-color: #613894 !important;
  color: #ffffff !important;
  font-weight: bold;
  font-size: 20px !important;
  text-transform: none !important;
  font-family: Ubuntu-Bold;
}

.homePageCont2 {
  margin-top: 1rem;
  margin-left: auto;
}

.aboutPageCont2 {
  display: flex;
  width: 50%;
  justify-content: center;
  margin: 0 auto;
}

.homePageButtonCont {
  display: flex;
  flex-direction: column;
  width: 35%;
  align-items: center;
  height: 350px;
}

.homePageButtonText {
  margin: 0px;
  color: #623a96;
  font-size: 16px;
  font-family: Ubuntu;
}

.homePageButtonText2 {
  margin: 0px;
  color: #636466;
  font-size: 17px;
  font-family: Ubuntu-Light;
}

.aboutPageButtonText2 {
  margin: 10px auto;
  color: #636466;
  font-size: 17px;
  font-family: Ubuntu-Light;
}

.headerPage {
  width: 100%;
  background-color: #613894;
  height: 4.8vh;
  position: fixed;
  top: 0;
  z-index: 10000;
}

.footerPage {
  width: 100%;
  background-color: #613894;
  height: 6.2vh;
  position: fixed;
  bottom: 0;
  z-index: 10000;
  margin-top: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.footerLinks {
  float: right;
  padding: 0 30px;
}

.footerPage a {
  font-size: 20px;
  color: #9d9fa2;
  font-family: Ubuntu-Light;
}

.footerPage span {
  font-size: 20px;
  color: #9d9fa2;
  font-family: Ubuntu-Light;
}

.float-right {
  float: right;
}

.App a {
  text-decoration: none;
}

.mandatory.asterisk {
  color: #ffcb11;
  font-size: 17px;
}

.surveyPage {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;
  padding-top: 30px;
  text-align: left;
  margin-top: 50px;
  margin-bottom: 50px;
}

.requiredLabel {
  margin: 40px 0px;
  text-align: right;
  font-size: 0.9rem;
  font-weight: bold;
}

.reflectOnDecisionWrapper {
  margin: 40px 0px;
}

.reactSelect {
  width: 100%;
}

.reactSelect > .css-26l3qy-menu {
  z-index: 999999;
}

.surveyForm .customSelect {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 45%;
  float: left;
  margin: 2%;
}

@media (max-width: 766px) {
  .surveyForm .customSelect {
    width: 100%;
  }
}

.decisionForm .customSelect {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  float: left;
  margin: 2%;
}

.customSelect select {
  margin-top: 10px;
  min-width: 200px;
  padding: 5px;
}

.objectItem {
  justify-content: center;
  display: flex;
}

.objectItem .left {
  width: 40%;
  text-align: right;
  padding-right: 10px;
}

.previewButtons {
  display: flex;
  justify-content: space-around;
}

.decisionCreatorWrapper button,
.orderDecisionsWrapper button,
.futureDecisions button {
  float: right;
}

.decisionCreatorWrapper {
  margin-bottom: 20px;
}

.objectItem .right {
  width: 60%;
  color: #6d6d6d;
}

.customSelect .label {
  font-size: 17px;
  margin-top: 10px;
  color: #613894;
  font-weight: bold;
  font-family: Ubuntu-Bold;
  line-height: 1.5;
  text-align: left;
}
@media (max-width: 766px) {
  .customSelect .label {
    text-align: left;
  }
}

.contactForm label {
  font-size: 17px;
  color: #623a96;
  font-weight: bold;
  font-family: Ubuntu-Bold;
}

.selectDivider {
  height: 30px;
  width: 50%;
}

.decisionsChartPage {
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.backNextWrapper {
  width: 100%;
  margin-top: 1rem;
  float: right;
}

.backNextWrapper2 {
  width: 100%;
  padding-top: 2rem;
  display: flex;
  flex-flow: row-reverse;
}

.introText {
  padding-top: 50px;
  text-align: left;
}

.captchaCont {
  text-align: center;
  padding-left: 30%;
  padding-top: 5%;
  padding-bottom: 5%;
}

.results {
  color: #636466;
  font-size: 17px;
  line-height: 1.5;
}

.resultQuestion {
  font-size: 17px;
  margin: 2rem -1rem 1rem -1rem;
  color: #613894;
  font-weight: bold;
  font-family: Ubuntu-Bold;
  line-height: 1.5;
}

.seeResultsAgainText {
  text-align: left;
}

.orderingWrapper ul {
  position: relative;
  z-index: 0;
  background-color: #f3f3f3;
  border: 1px solid #efefef;
  border-radius: 3px;
  outline: none;

  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #e0e0e0;
  list-style: none;
  padding: 0;
}

.orderingWrapper ul li,
.SortableHelper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  background-color: #fff;
  /* border-bottom: 1px solid #efefef; */
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  font-weight: 500;
}
.SortableHelper {
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2),
    0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  cursor: row-resize;
}

.customDecisionInput {
  width: 100%;
  margin: 0;
  margin-top: 10px;
  padding: 9px 12px;
  font-size: 17px;
  box-sizing: border-box;
  border: none;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  border-color: #cccccc;
}

.enterCodeWrapper {
  padding-top: 50px;
  width: 50%;
  margin: 0 auto;
}

@media (max-width: 766px) {
  .enterCodeWrapper {
    width: 80%;
  }
}

.codeInput {
  margin: 0 !important;
}

.buttonsWrapper {
  margin-top: 15px !important;
  background-color: #fec70f !important;
  color: #ffffff !important;
  font-family: Ubuntu !important;
  text-transform: none !important;
}

.rankingPage {
  width: 80%;
  margin: 0 auto;
  height: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.ranking-li{
  padding: 0 20px;
  height: 60px;
  display: flex;
  align-items: center
}

.rankingPage .intro {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin: 30px 0px;
}

.rankingChartWrapper {
  width: 100%;
  height: 550px;
}

.AllRankingChartWrapper {
  width: 100%;
  height: 550px;
  margin-top: 50px;
}

.rankingChartParent {
  width: 100%;
  height: 80%;
}

.decisionsChartWrapper {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quarters .label {
  text-align: left;
  color: #636466;
  font-size: 17px;
  line-height: 1.5;
}

.quarters .percents {
  text-align: left;
  color: #636466;
  font-size: 17px;
  line-height: 2;
}

.quarters .loader {
  display: flex;
  justify-content: center;
  padding: 100px 0;
}

.quarters .legend {
  border: 1px solid gray;
  width: fit-content;
  padding: 10px 10px 0 10px;
  position: absolute;
  top: 55%;
  right: 10px;
}

.addFriend {
  margin-top: 13px;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (max-width: 766px) {
  .addFriend {
    flex-direction: column;
    align-items: inherit !important;
    justify-content: center !important;
    margin: 0 auto;
    max-width: 310px !important;
  }
}

.addFriendLabel {
  margin-top: 15px;
}

.addFriend .addFriendBtn {
  margin-left: 30px;
  margin-top: 9px;
}

@media (max-width: 766px) {
  .addFriend .addFriendBtn {
    margin-left: 0px;
  }
}

.addFriendInput {
  height: 10px;
}

.ageGenderSelects {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin: 1rem 0;
}

.ageGenderSelects .customSelect {
  width: 60%;
  margin: 0 auto;
}

@media (max-width: 766px) {
  .ageGenderSelects .customSelect {
    width: 100% !important;
  }
}

.ageGenderWrapper .showComparisonBtn {
  margin-top: 20px;
}

.category {
  margin-bottom: -2rem;
}

.comparison .decisions,
.comparison .topDecisions {
  text-align: left;
}

.comparison .label {
  font-weight: bold;
  text-align: center;
  margin-top: 0.5rem;
}

.comparison .decisions div,
.comparison .topDecisions div {
  border-style: solid;
  border-width: 1px;
  padding: 5px;
}

.feedback .MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

.comparisonPage,
.similarityPage,
.ageComparePage,
.evaluationsPage,
.sendMailPage,
.learnMore {
  font-family: Ubuntu;
  line-height: 1.5;
  font-size: 17px;
  color: #636466;
}

.comparisonPage .comparison {
  display: flex;
  justify-content: center;
}

.comparisonPage .comparison .self {
  width: 45%;
}

@media (max-width: 835px) {
  .comparisonPage .comparison .self {
    width: 50%;
  }
}

.comparisonPage .comparison .others {
  width: 45%;
}

@media (max-width: 835px) {
  .comparisonPage .comparison .others {
    width: 50%;
  }
}

.similarityPage {
  text-align: left;
}

.similarityPage .pageTitle {
  text-align: left;
  margin-bottom: 15px;
}

.similarityChartWrapper {
  height: 300px;
  width: 100%;
  margin: 0 auto;
}

.decisionAgeGenderSelection {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
}

.decisionAgeGenderSelection .decisionSelect {
  width: 60%;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: -1rem;
}

.decisionAgeGenderSelection .ageGenderSelect {
  width: 100%;
}

@media (max-width: 766px) {
  .decisionAgeGenderSelection .decisionSelect {
    width: 100%;
  }
}

@media (max-width: 766px) {
  .decisionAgeGenderSelection .ageGenderSelect {
    width: 100%;
    margin-left: 0;
  }
}

.agesChart {
  width: 100%;
  height: 400px;
}

.evaluationsPage a {
  font-family: Ubuntu-LightItalic;
  color: #623a96;
  font-size: 16px;
  text-decoration: underline;
}

.evaluationsChart {
  margin-top: 30px;
  width: 100%;
  height: 400px;
}

div.tooltip {
  position: absolute;
  text-align: center;
  padding: 7px;
  font: 12px sans-serif;
  background: #415f86;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
  color: white;
  font-weight: bold;
}

.evaluationsChart .tick line,
.evaluationsChart .domain {
  display: none;
}

.sendMailPage {
  text-align: left;
}

.groupYourEmail {
  display: flex;
}

.groupEmailTextField {
  width: 40%;
}

@media (max-width: 1300px) {
  .groupYourEmail {
    flex-direction: column;
  }
}

@media (max-width: 766px) {
  .groupEmailTextField {
    width: 100% !important;
  }
}

.sendMailPage .policy {
  font-size: 0.8rem;
  text-align: center;
  width: 60%;
  margin: 0 auto;
}

.sendMailPage .sendMail {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.friendEmail {
  margin: 31px 0px;
}

.termsPage,
.privacyPage {
  margin: 0 100px;
  margin-bottom: 100px;
}

.aboutPage .title {
  margin-bottom: 0.21cm;
  line-height: 200%;
  text-align: center;
  background: transparent;
  font-size: 16px;
  font-weight: bold;
}

.aboutPage .paragraph {
  text-indent: 0.75cm;
  margin-bottom: 0cm;
  line-height: 200%;
  text-align: left;
  background: transparent;
  font-family: 'Times New Roman', serif;
  font-size: 16px;
}

.privacyPage h1,
.termsPage h1 {
  margin-bottom: 0.21cm;
  line-height: 200%;
  text-align: center;
  background: transparent;
  font-family: 'Times New Roman', serif;
  font-size: 16px;
  font-weight: bold;
}

.privacyPage p,
.termsPage p {
  text-indent: 0.75cm;
  margin-bottom: 0cm;
  line-height: 200%;
  text-align: left;
  background: transparent;
  font-family: 'Times New Roman', serif;
  font-size: 16px;
}

.privacyPage h2,
.termsPage h2 {
  margin-bottom: 0.21cm;
  line-height: 200%;
  text-align: left;
  background: transparent;
  font-family: 'Times New Roman', serif;
  font-size: 16px;
  font-weight: bold;
}

.shareWrapper {
  display: flex;
  justify-content: center;
  margin: 30px 0px;
  margin-bottom: 0;
  font-family: Ubuntu;
  line-height: 1.5;
  font-size: 17px;
  color: #636466;
}

.shareIcon {
  margin: 0 7px;
}

.aboutImgCont {
  width: 30%;
  margin-top: 1.5rem;
  float: left;
}

@media (max-width: 766px) {
  .aboutImgCont {
    width: 100%;
  }
}

.aboutTextCont {
  width: 65%;
  float: right;
  text-align: start;
}

@media (max-width: 766px) {
  .aboutTextCont {
    width: 100%;
  }
}
.aboutTextCont .aboutText1 {
  font-family: Ubuntu;
  line-height: 1.5;
  font-size: 14px;
  color: #636466;
}

.aboutTextCont .aboutText2 {
  font-family: Ubuntu-LightItalic;
  line-height: 1.5;
  font-size: 17px;
  color: #636466;
}

.aboutText2 .aboutText3 {
  font-family: Ubuntu-LightItalic;
  color: #623a96;
  font-size: 16px;
  text-decoration: underline;
}

.supportTextCont {
  line-height: 1.5;
  width: 100%;
  float: right;
  text-align: start;
}

.supportTextCont .supportText1 {
  font-family: Ubuntu;
  font-size: 17px;
  color: #636466;
}

.supportTextCont .supportText2 {
  font-family: Ubuntu-LightItalic;
  color: #636466;
}

.supportText1 .supportText3 {
  font-family: Ubuntu-LightItalic;
  color: #623a96;
  font-size: 16px;
  text-decoration: underline;
}

.aboutImgCont img {
  width: 100%;
}

.aboutContent {
  padding: 5%;
}

.termsContent {
  padding: 5%;
  text-align: start;
}

.contactForm {
  display: flex;
  flex-direction: column;
}

.required {
  color: #ffcb11;
  font-size: 17px;
  font-weight: bold;
  margin-top: -25px;
  font-family: Ubuntu-Bold;
}

.MuiInputBase-root {
  height: 35px;
}

.MuiFormControl-root {
  margin-top: 10px !important;
}

.textBox {
  max-width: 500px;
  margin-left: 65px !important;
}

.textBox-1{
  width:300px;
}

.news-letter-fields {
  display: flex;
  justify-content: space-between;
}

.textbox1{
  margin-left: 65px;
  margin-top: 10px;
}

@media (max-width: 766px) {
  .textBox {
    margin-left: 0px !important;
  }
  .news-letter-fields{
    flex-direction: column;
  }
}

.descriptionBox {
  max-width: 640px;
  margin-left: 65px !important;
}

@media (max-width: 766px) {
  .descriptionBox {
    margin-left: 0px !important;
  }
}

.contactButton {
  background-color: #fec70f !important;
  color: #ffffff !important;
  font-weight: bold !important;
  width: 15%;
  font-size: 20px !important;
  border-radius: 0px !important;
  font-family: Ubuntu;
  margin-left: auto !important;
  text-transform: none !important;
  box-shadow: none !important;
}

.nextButton {
  background-color: #fec70f !important;
  color: #ffffff !important;
  width: 10%;
  font-size: 20px !important;
  border-radius: 0px !important;
  float: right !important;
  margin-right: 4% !important;
  text-transform: none !important;
  font-family: Ubuntu !important;
}

@media (max-width: 766px) {
  .nextButton {
    margin-right: -10px !important;
  }
}

.prevButton {
  background-color: #ffffff !important;
  color: #ffcb11 !important;
  font-size: 17px !important;
  font-weight: bold !important;
  float: right !important;
  margin-right: 4% !important;
  text-transform: none !important;
  /* border: 1px solid #FFCB11 !important; */
  font-family: Ubuntu-Bold !important;
}

.jumpToPrivacy {
  float: right;
  font-family: Ubuntu-LightItalic;
  font-size: 16px;
  color: #623a96;
}

@media (max-width: 766px) {
  .jumpToPrivacy {
    margin-right: 20px !important;
  }
}

.jumpToTopCont {
  width: 100%;
  justify-content: center;
  text-align: center;
}

.jumpToTop {
  font-family: Ubuntu-LightItalic;
  color: #623a96;
}

.lastUpdated {
  font-family: Ubuntu-LightItalic;
  font-size: 17px;
  color: #636466;
}

.privacyTitleText {
  color: #ffcb11;
  font-size: 17px;
  font-weight: bold;
  font-family: Ubuntu-Bold;
  margin-bottom: 0;
  line-height: 1.5;
}

.privacyText1 {
  margin-top: 0;
  text-align: justify;
  line-height: 1.5;
  font-size: 17px;
  color: #636466;
  font-family: Ubuntu;
}

.privacyText1 .privacyText3 {
  font-family: Ubuntu-LightItalic;
  font-size: 17px;
  color: #623a96;
  text-decoration: underline;
}

.userDetailsWrapper {
  margin: 2%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.surveyPage h1 {
  color: #fec70f !important;
}

.decisionFormText {
  width: 90%;
  margin: auto;
  line-height: 1.5;
  text-align: justify;
}

.decisionFormText h2 {
  color: #ffcb11 !important;
  font-size: 17px;
  font-weight: bold;
}

.decisionFormSub {
  width: 100%;
  justify-content: center;
  margin: auto;
}

@media (max-width: 766px) {
  .decisionFormSub {
    width: 100%;
  }
}
.contactText1 {
  line-height: 1.5;
  font-family: Ubuntu;
  font-size: 17px;
  color: #636466;
}

.decisionFormText p {
  font-family: Ubuntu-MediumItalic;
  font-size: 17px;
  color: #636466;
}

.decisionProgressCont {
  position: fixed;
  z-index: 100000;
  width: 100%;
  top: 25px;
  right: 15px;
}

.decisionProgressCont img {
  width: 20%;
  float: right;
}

@media (max-width: 766px) {
  .decisionProgressCont {
    top: auto;
    bottom: 35px;
  }
}
@media (max-width: 766px) {
  .decisionProgressCont img {
    width: 76%;
    margin: 0 12%;
    height: 40px;
  }
}

.grecaptcha-badge {
  top: 50% !important;
}

.css-yk16xz-control {
  border-radius: 0 !important;
}

.sendMailButton {
  background-color: #fec70f !important;
  color: #ffffff !important;
  width: 10%;
  font-size: 15px !important;
  border-radius: 0px !important;
  text-transform: none !important;
  font-family: Ubuntu !important;
}

.confirmDecisionWrapper {
  padding: 1rem;
}

.decisionPreview-subtitle {
  text-align: center;
}
.text{
  list-style-type: none;
}
.font-normal{
  font-family: Ubuntu !important;
}

.link {
  font-family: Ubuntu-LightItalic;
  color: #623a96;
  font-size: 16px;
  text-decoration: underline !important;
  cursor: pointer;
}
